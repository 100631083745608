import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_t = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - T'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>T</h2>
     <LatQuotesIntro />
    
     <p><b>Tabula rasa</b> - A clean slate. Person that knows nothing</p>
<p><b>Tamdiu discendum est, quamdiu vivas</b> - We should learn as long as we may live. (We live and learn.) (Seneca Philosophus)</p>
<p><b>Tamquam alter idem</b> - As if a second self. (Cicero)</p>
<p><b>Tanta stultitia mortalium est</b> - Such is the foolishness of mortals</p>
<p><b>Tantum eruditi sunt liberi</b> - Only the educated are free. (Epictetus)</p>
<p><b>Tantum religio potuit suadere malorum</b> - So potent was religion in persuading to evil deeds. (Lucretius)</p>
<p><b>Tarditas et procrastinatio odiosa est</b> - Delay and procrastination is hateful. (Cicero)</p>
<p><b>Te capiam, cunicule sceleste!</b> - I'll get you, you wascally wabbit!</p>
<p><b>Te Deum</b> - Thee, God [we praise]</p>
<p><b>Te igitur</b> - Thee, therefore</p>
<p><b>Te nosce</b> - Know thyself</p>
<p><b>Tempora mutantur, nos et mutamur in illis</b> - The times change, and we change with them. (John Owen)</p>
<p><b>Tempore</b> - In the time of</p>
<p><b>Tempus edax rerum</b> - Time is the devourer of things (time flies)</p>
<p><b>Tempus fugit</b> - Time flees</p>
<p><b>Tempus fugit, non autem memoria</b> - Time flies, but not memory</p>
<p><b>Tempus incognitum</b> - Time unknown</p>
<p><b>Tempus neminem manet</b> - Time waits for no one</p>
<p><b>Tempus omnia sed memorias privat</b> - Time deprives all but memories</p>
<p><b>Ter in die (t.i.d.)</b> - Three times a day</p>
<p><b>Terminus a quo</b> - The end from which</p>
<p><b>Terminus ad quem</b> - The end to which</p>
<p><b>Terra firma</b> - Solid ground</p>
<p><b>Terra incognita</b> - Unknown land</p>
<p><b>Terra nullius</b> - Uninhabited land</p>
<p><b>Tertium quid</b> - A third something</p>
<p><b>Tetigisti acu</b> - You have hit the nail on the head. (Plautus)</p>
<p><b>Theatrum mundi</b> - The theatre of the world</p>
<p><b>Timendi causa est nescire</b> - Ignorance is the cause of fear. (Seneca)</p>
<p><b>Timeo Danaos et dona ferentes</b> - I fear the Greeks, even when they bring gifts. (Virgil)</p>
<p><b>Timor mortis conturbat me</b> - The fear of death confounds me</p>
<p><b>Tintinnuntius meus sonat!</b> - There goes my beeper!</p>
<p><b>Tolerabiles ineptiae</b> - Bearable absurdities</p>
<p><b>Totidem verbis</b> - In so many words</p>
<p><b>Trahimur omnes laudis studio</b> - We are all led on by our eagerness for praise. (Cicero)</p>
<p><b>Transire suum pectus mundoque potiri</b> - To overcome one's human limitations and become master of the universe</p>
<p><b>Transit umbra, lux permanet</b> - Shadow passes, light remains (On a sun dial)</p>
<p><b>Tu autem</b> - You, also</p>
<p><b>Tu fui, ego eris</b> - What you are, I was. What I am, you will be. (This is found on graves and burial sites)</p>
<p><b>Tu ne cede malis sed contra audentior ito</b> - Yield not to misfortunes, but advance all the more boldly against them</p>
<p><b>Tu quoque</b> - You likewise</p>

   </Layout>
  )
}

export default LatQuotes_t
